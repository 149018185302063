// Services
import callAPI from "../callApi";
import path from "../variables";

const Passengers = {
	getAll: (filter?: any) =>
		callAPI(
			"GET",
			`${path.base}/tickets/passengers${filter ? filter : ""}`,
			"",
			"getting all passengers"
		),
	create: (data: any) =>
		callAPI("POST", `${path.base}/tickets/passengers`, data, "register new passenger"),
	update: (data: any) =>
		callAPI("PUT", `${path.base}/tickets/passengers/${data.id}`, data, "update passenger info"),
};

export default Passengers;
