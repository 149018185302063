// Services
import callAPI from "../callApi";
import path from "../variables";

const Auth = {
	login: (data: any) => callAPI("POST", `${path.base}/auth/login`, data, "login User"),
	logout: () => callAPI("GET", `${path.base}/auth/logout`, "", "logout user"),
	register: (data: any) => callAPI("POST", `${path.base}/auth/register`, data, "registering User"),

	updatePersonalInfos: (data: any) => callAPI("POST", `${path.base}/auth/update/infos`, data, "updateing User Infos"),
	updateCompanyInfos: (data: any) => callAPI("POST", `${path.base}/auth/update/organisation/infos`, data, "updateing Company Infos"),

	newUser: (data: any) => callAPI("POST", `${path.base}/user/new`, data, "create a new User"),
	validateAccount: (data: any) =>
		callAPI("POST", `${path.base}/auth/activate`, data, "validating account"),

	initForgotPassword: (data: any) =>
		callAPI(
			"POST",
			`${path.base}/auth/init-reset-password`,
			data,
			"init requesting to reset password"
		),
	forgotPassword: (data: any) =>
		callAPI("POST", `${path.base}/auth/reset-password`, data, "requesting reset password"),

	verifyCode: (data: any) =>
		callAPI(
			"POST",
			`${path.base}/auth/verify_code`,
			data,
			"requesting verify code for new password"
		),
	resetPassword: (data: any) =>
		callAPI("POST", `${path.base}/auth/reset-password`, data, "reseting password"),
	refreshToken: (data: any) =>
		callAPI("POST", `${path.base}/auth/refresh`, data, "refreshing token"),
	changePwd: (data: { old_password: string; password: string; passwordConfirm: string }) =>
		callAPI("POST", `${path.base}/new_password`, data, "Changing Password"),
	resendEmail: (data:any) =>
		callAPI("POST", `${path.base}/auth/resend/mail`, data, "Resending E-mail"),
};

export default Auth;
