import { ref, watchEffect } from "vue";
import router from "@/router";
import axios from "axios";
import axiosRetry from "axios-retry";
import { detectPlatform, validToken } from "@/utils";
import {
	saveToken,
	authStatus,
	updateAuthStatus,
} from "@/state/api/authState";

/** Default config for axios instance */
const config = {
	baseURL: process.env.VUE_APP_API,
	timeout: 120000,
	headers: {
		"Content-Type": "application/json",
	},
};
const platform = detectPlatform();

const tokenExpError = ref(0);

/**
 * Axios intance
 */
const jahagal = axios.create(config);

// Define max request retry
axiosRetry(jahagal, { retries: 3 });

/**
 * Logger interceptor
 */
const loggerInterceptor = (config: any) => {
	return;
};

/**
 * Request interceptor
 */
jahagal.interceptors.request.use((question: any) => {
	// Do something before request is sent
	// console.log("Request: ", question);
	return question;
});

/**
 * Response interceptor
 */
jahagal.interceptors.response.use(
	(response: any) => {
		switch (response.status) {
			case 200:
				tokenExpError.value = 0;
				break;
			// any other cases
			default:
			// default case
		}

		return response;
	},
	async (error: any) => {
		// functions
		const refreshToken = (refresh_token: any) => {
			return new Promise((resolve, reject) => {
				watchEffect(() => {
					if (
						authStatus.value != "refreshing" &&
						authStatus.value != "refreshed" &&
						authStatus.value != "disconnected"
					) {
						// Change auth status to token is refreshing
						updateAuthStatus("refreshing");
						// Launch API call
						jahagal
							.post(`/api/refresh`, { refresh_token })
							.then((response: any) => {
								// Change auth status to signal token refreshed
								updateAuthStatus("refreshed");
								// handle success
								console.log(
									`%c Response success when refreshing token`,
									"color: #5CC62C;",
									response
								);

								saveToken(response.data).then(() => {
									// Change auth status to signal user connected
									updateAuthStatus("connected");

									// Send new token
									resolve(response.data.data.token);
								});
							})
							.catch((err: any) => {
								router.push({
									name: "auth-logout",
									params: { action: "force" },
								});
							});
					}
				});
			});
		};

		// all the error responses
		if (error.response) {
			const token = validToken();
			switch (error.response.status) {
				case 400:
					console.error(error.response.status, error.message);
					break;

				case 401: // authorisation error
					console.error(error.response.status, "Action non autorisée !");
					break;

				case 403: // Authentification error
					console.error(error.response.status, error.message);
					break;

				case 404: // Not found
					console.error(error.response.status, error.message);
					break;

				case 498: // Token expired
					console.error(error.response.status, error.message);
					router.push({
						name: "auth-logout",
						params: { action: "force" },
					});
					// window.location.href = "/auth/logout/force";
					break;

				case 499: // Refresfh token expired
					console.error(error.response.status, error.message);
					router.push({
						name: "auth-logout",
						params: { action: "force" },
					});
					break;

				default:
					console.error(error.message);
			}
		}

		if (!error.response?.status) {
			console.error("Network error");
		}

		return Promise.reject(error);
	}
);

export default jahagal;
