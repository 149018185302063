import Chance from 'chance';
import dayjs from 'dayjs';

const chance = new Chance();

// Liste des marques de voitures
const colors = [
  'Vert', 'Rouge', 'Jaune', 'Orange', 'Violet', 'Marron', 'Rose', 'Bleu', 'Noir', 'Blanc'
];

const carBrands = [
  'Toyota', 'Ford', 'Chevrolet', 'Honda', 'Nissan', 'BMW', 'Mercedes-Benz', 'Volkswagen', 'Audi', 'Hyundai'
];

// Liste des modèles de voitures
const carModels = [
  'Corolla', 'Mustang', 'Camaro', 'Civic', 'Altima', '3 Series', 'C-Class', 'Golf', 'A4', 'Elantra'
];

// Liste des fabricants de voitures
const carManufacturers = [
  'Toyota Motor Corporation', 'Ford Motor Company', 'General Motors', 'Honda Motor Co., Ltd.',
  'Nissan Motor Co., Ltd.', 'Bayerische Motoren Werke AG (BMW)', 'Daimler AG (Mercedes-Benz)',
  'Volkswagen AG', 'Audi AG', 'Hyundai Motor Company'
];

// Fonction pour générer un numéro VIN factice
const generateCarVin = () => {
  const vin = chance.string({ length: 17, pool: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789' });
  return vin;
};

// Fonction pour générer une plaque d'immatriculation factice
const generateCarLicense = () => {
  const plate = chance.string({ length: 7, pool: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789' });
  return plate;
};

// Fonction pour générer des modèles de voitures
const generateCarModel = () => {
  const carModel = chance.pickone(carModels); // Sélectionner un modèle de voiture aléatoire
  return carModel
};

// Fonction pour générer des marques de voitures
const generateCarBrand = () => {
  const carBrand = chance.pickone(carBrands); // Sélectionner une marque de voiture aléatoire
  return carBrand
};


const generateYear = () => {
  const year = chance.year({ min: 1980, max: new Date().getFullYear() });
  return year
};

// Générer une couleur aléatoire en format hexadécimal
const generateColorHex = () => {
  const color = chance.color({ format: 'hex' });
  return color
};

// Générer // Sélectionner un fabricant de voiture aléatoire
const generateManufacturers = () => {
  const name = chance.pickone(carManufacturers);
  return name
};

// Générer une couleur aléatoire en format humain
const generateColor = () => {
  const color = chance.pickone(colors);
  return color
};

// Générer une description aléatoire de 10 mots
const generateSentence = () => {
  const description = chance.sentence({ words: 10 });
  return description
};

// Fonction pour générer une date aléatoire formatée
const generateRandomDate = () => {
  const randomDate = chance.date({ year: chance.year({ min: 1980, max: new Date().getFullYear() }) as unknown as number});
  return dayjs(randomDate).format('YYYY-MM-DD');
};

const randomName: string = chance.name();

const randomEmail: string = chance.email();

// Générer chaque type de caractère requis
const upper = chance.character({ pool: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' });
const lower = chance.character({ pool: 'abcdefghijklmnopqrstuvwxyz' });
const number = chance.character({ pool: '0123456789' });
const symbol = chance.character({ pool: "!@#$&*_+=" });

// Générer les caractères restants
const remaining = chance.string({ length: 4, pool: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$&*_+:;=' });

const ramdomNumber = chance.string({ length: 2, pool: '0123456789' });

// Combiner tous les caractères
const passwordArray = [upper, lower, number, symbol, ...remaining];

// Mélanger les caractères pour obtenir un mot de passe aléatoire
const randompassword = chance.shuffle(passwordArray).join('');

// Générer une adresse aléatoire
const randomAddress: string = chance.address();

const randomPhone: string = chance.phone();

const randomZip: string = chance.zip();

export {
  generateCarBrand,
  generateCarModel,
  generateCarVin,
  generateCarLicense,
  generateYear,
  generateColor,
  generateColorHex,
  generateManufacturers,
  generateSentence,
  generateRandomDate,
  randomName,
  randomEmail,
  randompassword,
  ramdomNumber,
  randomAddress,
  randomPhone,
  randomZip
}
