// Services
import callAPI from "../callApi";
import path from "../variables";

const AutoPack = {
	vehicles: {
		getAll: (filter?: any) =>
			callAPI(
				"GET",
				`${path.base}/automobile/vehicles${filter ? filter : ""}`,
				"",
				"getting all vehicles"
			),
		create: (data: any) =>
			callAPI("POST", `${path.base}/automobile/vehicles`, data, "creating new vehicle"),
	},
	vehiclesType: {
		getAll: () =>
			callAPI("GET", `${path.base}/automobile/vehicles/vtypes`, "", "getting all vehicle types"),
		create: (data: any) =>
			callAPI("POST", `${path.base}/automobile/vehicles/vtypes`, data, "creating new vehicle type"),
	},
	technicalVisit: {
		getAll: () =>
			callAPI("GET", `${path.base}/automobile/technicalvisits`, "", "getting all technical visits"),
		create: (data: any) =>
			callAPI(
				"POST",
				`${path.base}/automobile/technicalvisits`,
				data,
				"creating new technical visits entry"
			),
	},
	technicalVisitStatus: {
		getAll: () =>
			callAPI(
				"GET",
				`${path.base}/automobile/technicalvisitstatus`,
				"",
				"getting all technical visits statuses"
			),
		create: (data: any) =>
			callAPI(
				"POST",
				`${path.base}/automobile/technicalvisitstatus`,
				data,
				"creating new technical visits status entry"
			),
	},
	maintenance: {
		getAll: () =>
			callAPI("GET", `${path.base}/automobile/maintenances`, "", "getting all maintenances"),
		create: (data: any) =>
			callAPI(
				"POST",
				`${path.base}/automobile/maintenances`,
				data,
				"creating new maintenance entry"
			),
	},
	insurance: {
		getAll: () =>
			callAPI("GET", `${path.base}/automobile/insurances`, "", "getting all insurances"),
		create: (data: any) =>
			callAPI("POST", `${path.base}/automobile/insurances`, data, "creating new insurance entry"),
	},
	beverage: {
		getAll: () => callAPI("GET", `${path.base}/automobile/beverages`, "", "getting all beverages"),
		create: (data: any) =>
			callAPI("POST", `${path.base}/automobile/beverages`, data, "creating new beverage entry"),
	},
};

export default AutoPack;
