import { ref } from "vue";
import { useStorage } from "@vueuse/core";
import api from "@/api";

type ConfigKey = {
	lang: any;
	theme: string;
};

export const storageConfig = useStorage<ConfigKey>("session_lang", {
	lang: process.env.VUE_APP_I18N,
	theme: "dark",
});

export const userInfos = ref<any>(null);
export const currentStation = ref<any>({});
export const userID = userInfos.value?.id;
export const userStats = ref<any>({});

export function updateUserInfos(infos: any) {
	if (infos != null) {
		if (infos.length != 0) {
			userInfos.value = infos;
		}
	}
}

export function refreshUserInfos() {
	return new Promise<any>((resolve, reject) => {
		api()
			.user.getUserInfos()
			.then((userInfos: any) => {
				// Save user infos
				updateUserInfos(userInfos.data);
				resolve("done");
			})
			.catch((err: any) => {
				reject(err);
			});
	});
}

export function resetUserInfos() {
	userInfos.value = null;
}

export function hasStation() {
	return userInfos.value.stations.length > 1;
}

export function singleStationID() {
	return userInfos.value.stations[0].id;
}

// strictMode = true; n'integre pas la vue d'ensemble dans le tableau des agences
export async function getAllStationsUser(policy = { strictMode : true}) {
	const stations = await userInfos.value.stations.map((station: any) => {
		return { key: station.id, value: station.station_name };
	});

	if (!policy.strictMode)
	stations.unshift({ key: -1, value: "Vue d'ensemble" })

	return stations;
}

export async function refreshStationsUser() {
	let stations = [];
	await refreshUserInfos()
	stations = await getAllStationsUser();
	if (stations.length != 0) {
		currentStation.value = stations[0];
	}
}
