export default {
	global: {
		password: /^.*(?=.{8,})(?=..*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/,
		email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]{2,10}$/,
		phone: /^[+]?[0-9]{6,}$/,
		userName: /^[\w\-. ]{3,}$/,
		postalBox: /^[\w\-. ]{3,}$/,
		shortText: /^[\w\- À-ÖØ-öø-ÿ]{3,}$/,
		text: /^[\w\WÀ-ÖØ-öø-ÿ]{3,}$/, // [a-zA-Z0-9_] + tout caracteres d'espacement y compris '_' + tout caracteres hors mis [a-zA-Z0-9_]
		address: /^[\w\- À-ÖØ-öø-ÿ]{5,}$/,
		verificationCode: /^[-0-9A-Za-z]{6,}$/,
		otp: /^[-0-9A-Za-z]{6,}$/,
		date: /(19|20)[0-9]{2}[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])/,
		time: /^((0|1|2)[0-9]{1})[:]((0|1|2|3|4|5)[0-9]{1})$/,
		fulltime: /^(0|1|2)[0-9]{1}[:](0-5)[0-9]{1}[:](0|1|2)[0-9]{1}$/,
		description: /^.{10,}$/,
		number: /^\d+$/,
		price: /^\d+[.\d]{0,8}$/,
		url: /^[^\s/$.?#].[^\s]*$/,
		urlSecured: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
	},
	station: {
		name: /^[\w ]{3,}$/,
	},
	vehicle: {
		// vehicle_type_id: //,
		name: /^[a-zA-Z0-9À-ÖØ-öø-ÿ]{3,}$/,
		mark_name: /^[\w\- À-ÖØ-öø-ÿ]{3,}$/,
		type: /^.{3,}$/,
		vin_no: /^[a-zA-Z0-9]{6,}$/,
		model_no: /^[A-Za-z0-9]+(?:[ -][A-Za-z0-9]+)*$/,
		made_year: /^(1|2)[0-9]{3}$/,
		color: /^[a-zA-Z ]{3,}$/,
		reservoir_capacity: /^\d{1,4}$/,
		place_number: /^[0-9]{1,3}$/,
		buying_price: /^\d{1,9}$/,
		description: /^.{10,}$/,
		manufacturer_name: /^[\w\-() ]{3,}$/,
		matriculation_no: /^\w{5,}$/,
	},
	passenger: {
		identity_no: /^\w{5,}$/,
	},
	passengerBaggage: {
		ticket_number: /^\d{9}$/,
	},
	staff: {
		account_number: /^\d{10,}$/,
	},
};
