// Services
import callAPI from "../callApi";
import path from "../variables";

const identityTypeCard = {
	getAll: () =>
		callAPI("GET", `${path.base}/others/typecard`, "", "getting all identity Type Card"),
};

const maritalStatus = {
	getAll: () =>
		callAPI("GET", `${path.base}/others/maritalstatus`, "", "getting all marital Status"),
};

const countries = {
	getAll: () =>
		callAPI("GET", `${path.base}/countries`, "", "getting all Countries"),
};

const cities = {
	getAll: (params:string) =>
		callAPI("GET", `${path.base}/countries/with/cities${params}`, "", "getting all Cities"),
};

const responsabilityFunction = {
	getAll: () =>
		callAPI(
			"GET",
			`${path.base}/others/responsabilityfunctions`,
			"",
			"getting all responsability Functions"
		),
};

export default { responsabilityFunction, identityTypeCard, maritalStatus, countries, cities };
