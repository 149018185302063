// Services
import callAPI from "../callApi";
import path from "../variables";

const route = {
	getAll: () => callAPI("GET", `${path.base}/planning/aa`, "", "getting all route dockets"),
};

const travel = {
	getAll: (filter:string) =>
		callAPI("GET", `${path.base}/reporting/traveldockets${filter ? filter : ""}`, "", "getting all travel dockets"),
};

export default { travel, route };
