import { ref } from "vue";
import TravelRoutesService from "@/services/pages/TravelRoutesService";
import VehicleService from "@/services/pages/VehicleService";
import DriverService from "@/services/pages/DriverService";
import TicketTypeService from "@/services/pages/TicketTypeService";
import StationService from "@/services/pages/StationService";
import { currentStation, userInfos, getAllStationsUser } from "@/state/api/userState";

export const statusOK = ref(true);

export const enableNextStepButton = ref(false); // active le bouton Next dans l'assistance de configuration popup

export const wizardInit = ref(false); // prepare a modal page to be open
export const wizardComplete = ref(false); // use to open a success modal page
export const wizardClose = ref(false); // use to close a success modal page

export const recapitulationInfo: any = ref({
	vehicle: {
		label: "véhicule",
		status: 1,
		step: 1,
		routeName: "workspace-autopark-vehicle",
		query: null
	},
	driver: {
		label: "chauffeur",
		status: 1,
		step: 2,
		routeName: "workspace-human-resources-staff",
		query: { tab: "driver" }
	},
	travelRoute: {
		label: "itinéraire",
		status: 1,
		step: 3,
		routeName: "workspace-planning-routes-and-rates",
		query: { tab: "itinary" }
	},
	ticketType: {
		label: "type de ticket",
		status: 1,
		step: 4,
		routeName: "workspace-planning-routes-and-rates",
		query: { tab: "ticketType" }
	},
	// stationService: { label: "véhicule", status: 1},
});

export const updateRecapilationInfoIfNecessary = async (param: string) => {
	if (!statusOK.value) {
		recapitulationInfo.value[param].status = 1;
		enableNextStepButton.value = true;
		await launchPrerequisitesService();
	}
};

export const resetDataPrerequisitesService = () => {
	wizardInit.value = false;
	wizardComplete.value = false;
	wizardClose.value = false;
	enableNextStepButton.value = false;
	statusOK.value = false;
};

export const launchPrerequisitesService = async (_currentStation:any = null) => {
	const stationID = _currentStation ? _currentStation.key : currentStation.value.key
	const travelRouteResp: any = await TravelRoutesService.getAll(
		"?station=" + stationID
	);
	const vehicleResp: any = await VehicleService.getAll("?station=" + stationID);
	const dvehicleResp: any = await DriverService.getAll("?station=" + stationID);
	const tTypeResp: any = await TicketTypeService.getAll("?station=" + stationID);
	// const eStationResp:any = await StationService.getAll("?station=" + stationID)

	if (
		(travelRouteResp && travelRouteResp.length == 0) ||
		(vehicleResp && vehicleResp.length == 0) ||
		(dvehicleResp && dvehicleResp.length == 0) ||
		(tTypeResp && tTypeResp.length == 0) /*|| eStationResp.length == 0*/
	) {
		statusOK.value = false;

		if (travelRouteResp.length == 0) recapitulationInfo.value.travelRoute.status = 0;
		if (vehicleResp.length == 0) recapitulationInfo.value.vehicle.status = 0;
		if (dvehicleResp.length == 0) recapitulationInfo.value.driver.status = 0;
		if (tTypeResp.length == 0) recapitulationInfo.value.ticketType.status = 0;
	} else if (
		(!travelRouteResp) ||
		(!vehicleResp) ||
		(!dvehicleResp) ||
		(!tTypeResp)
	) {
		statusOK.value = false;
	} else {
		statusOK.value = true;
		// fin de l'assistant de configuration
		wizardComplete.value = true;
	}
};

export default {
	run: async (currentStation = null) => launchPrerequisitesService(currentStation),
};
